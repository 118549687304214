* {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

p {
  color: white;
}

:root {

  --bg-color: #2c2c2c;
  --bg-color2: #474747;
  --bg-color_white: #dddddd;

  --black: #000000;
  --highlight-bg-color: #4b9ce2;
  --button-hover-bg-color: #ff7700;
}